const helper = {
  zaloToken(item) {},
};
export default helper;

export const STATUS_SYS = {
  SYS_EXIT: 1,
  SYS_FCNTL_NOCANCEL: 406,
};

export const PROJECT_TYPE = {
  duplex: "Duplex",
  four_bedroom: "4Bedroom",
  one_bedroom: "1Bedroom",
  penthouse: "Penthouse",
  three_bedroom: "3Bedroom",
  two_bedroom: "2Bedroom",
  villa: "Villa",
  studio: "Studio",
};

export const ROOM_TYPE = {
  living_room: "Living Room",
  bedroom: "Bedroom",
  kitchen: "Kitchen",
  bathroom: "Bathroom",
  other: "Other",
};

export const STYLE = {
  industrial: "Industrial",
  luxury: "Luxury",
  modern: "Modern",
  other: "Other",
  scandinavian: "Scandinavian",
};

export const FOLDER_IMAGE = {
  contractor_review: "contractor_review",
  inbox: "inbox",
  interior_share: "interior_share",
  manage_portfolio: "manage_portfolio",
  customer_center: "customer_center",
  myprofile: "myprofile",
};

export const USER_TYPE = {
  admin: "Admin",
  customer: "Customer",
  business: "Business",
  ["business,constructor"]: "Contractor",
};

export const ORDER_STATUS = {
  Pending: "pending",
  Purchased: "paid",
  Canceled: "cancel",
};

export const PAY_TYPE = {
  code: "VA",
  epay: "EW",
  gop: "IS",
  no: "NO",
  bank_transfer: "bank_transfer",
  visa_master: "IC",
  vnpay: "QR",
  atm_card: "DC",
};

export const PAYMENT_STATUS = {
  bank_transfer: "VA",
  visa_master: "IC",
  vnpay: "QR",
  atm_card: "DC",
};

export const PAYMENT_TYPE = {
  DC: "ATM Card",
  IC: "Visa/Master",
  VA: "Bank Transfer",
  QR: "VNPay",
  EW_MOMO: "Momo",
  EW_SHPP: "Shopee",
  EW_ZALO: "Zalo",
  EW: "E-Wallet",
  COD: "Cash on Delivery",
};

export const PRODUCT_STATUS = {
  SOLD_OUT: "Sold Out"
}

export const QUESTION_TYPE = {
  ITEM: "Item",
  DELIVERY: "Delivery",
  RETURN: "Return",
  CHANGE: "Change",
  CANCEL: "Cancel",
  REFUND: "Refund",
  OTHERS: "Others",
};

export const CUSTOMER_CENTER_HELP_TYPE = {
  DELIVERY: "Delivery",
  ORDER: "Order",
  CANCEL: "Cancel",
  REFUND: "Refund",
  CHANGE: "Change",
  ACCOUNT: "Account",
  MEMBERSHIP: "Membership",
  PAYMENT: "Payment",
  ITEM: "Item",
  OTHERS: "Others",
  COUPON_POINT: "Coupon/Point",
};

export const ORDER_STATUS_CODE = {
  PENDING: 1,
  WAITING_FOR_DELIVERY: 2,
  DELIVERY: 3,
  COMPLETED: 4,
  CONFIRMED: 5,
  CANCELLED: 6,
  REFUND_REQUEST: 7,
  EXCHANGE: 8,
  DOWNLOAD: 9,
  EXTEND_ORDER_CONFIRMATION: 10,
  REFUND_APPPROVED: 11,
  REFUND_DELIVERYING: 12,
  REFUND_CONFIRMED: 13,
  REFUND_COMPLETED: 14,
  REFUND_REJECT: 15,
};

export const SUB_ORDER_STATUS_CODE = {
  DELIVERING: 1,
  DELIVERY_DELAYED: 2,
  CANCEL_REQUESTED: 3,
  CANCEL_REQUEST_REJECTED: 4,
  CANCEL_CONFIRMED: 5,
  CANCEL_COMPLETED: 6,
  REFUND_REQUESTED: 7,
  REFUND_REQUEST_REJECTED: 8,
  REFUND_ITEM_DELIVERING: 9,
  REFUND_ITEM_RECEIVED: 10,
  REFUND_CONFIRMED: 11,
  REFUND_COMPLETED: 12,
  EXCHANGE_REQUESTED: 13,
  EXCHANGE_REQUEST_REJECTED: 14,
  EXCHANGE_REQUEST_ITEM_DELIVERING: 15,
  EXCHANGE_ITEM_RECEIVED: 16,
  EXCHANGE_CONFIRMED: 17,
  EXCHANGE_COMPLETED: 18,
  EXCHANGE_ON_HOLD: 19,
  RELEASE_EXCHANGE_ON_HOLD: 20,
  RE_DELIVERY_EXCHANGE: 21,
  REFUND_ON_HOLD: 22,
  RELEASE_REFUND_HOLD: 23,
  DELIVERED: 24,
  DELAYED_EXCHANGE: 25,
  WAITING_FOR_REFUND: 26,
  DELAYED_REFUND: 27,
  DELAYED_CANCEL: 28,
};

export const ORDER_STATUS_NAME = [
  "Chưa giải quyết",
  "Đang chờ giao hàng",
  "Vận chuyển",
  "Hoàn thành",
  "Đã xác nhận",
  "Đã hủy",
  "Đền bù",
  "Đổi",
  "Tải xuống",
];
export const ORDER_STATUS_NAMEEN = [
  "Pending",
  "Waiting for delivery",
  "Delivery",
  "Completed",
  "Confirmed",
  "Cancelled",
  "Refund",
  "Exchange",
  "Download",
];
export const ORDER_STATUS_NAMEKO = [
  "보류 중",
  "배송을 기다리는 중",
  "배달",
  "완전한",
  "확인됨",
  "취소 된",
  "환불하다",
  "교환",
  "다운로드",
];
