<template>
    <div class="vr360">
      <div v-html="vr360" id="vr360" style="height: 100vh"></div>
    </div>
</template>
<style lang="scss" scoped>
  @import '~sass-rem';
  .vr360 {
    width: 100%;
    #vr360 {
      width: 100%;
    }
  }

</style>
<script>
  export default {
    name: 'Vr360Index',
    data() {
        return {
          vr360: null
        };
      },
    created() {
      if (this.$route.params.slug === 'richmond') {
        this.vr360 = `<iframe width="100%" height="100%" frameborder="0" allow="xr-spatial-tracking; gyroscope; accelerometer" allowfullscreen scrolling="no" src="https://kuula.co/share/collection/7cHJn?logo=1&info=0&fs=1&vr=1&sd=1&autorotate=1.5&thumbs=-1&keys=0"></iframe>`
      }
    },
    methods: {
    },
    mounted() {},
    watch: {}
  };
</script>
  