<template>
  <div class="social-buttons">
    <div class="social-google">
      <div id="buttonDiv"></div>
      <p class="social-name pt-1 mb-0">Google</p>
    </div>
    <!-- <div class="social-item" @click="loginWithGoogle()">
      <img src="@/assets/images/icons/google.svg" alt="" />
      <p class="social-name pt-1 mb-0">Google</p>
    </div> -->
    <div class="social-item" @click="loginWithApple()">
      <img src="@/assets/images/icons/apple.svg" alt="" />
      <p class="social-name pt-1 mb-0">Apple ID</p>
    </div>
    <div>
      <vue-apple-login mode="center-align" ref="appleLogin" :onSuccess="onSuccess" :onFailure="onFailure"
        :style="{ display: 'none' }">
      </vue-apple-login>
    </div>
    <div class="social-item" @click="loginWithFacebook()">
      <facebook-login style="display: none" class="fb-login" loginLabel="" appId="1104938286978039" @logout="onLogout"
        @login="loginWithFacebook()"></facebook-login>
      <img src="@/assets/images/icons/facebook.svg" alt="" />
      <p class="social-name pt-1 mb-0">Facebook</p>
    </div>
    <div class="social-item" @click="loginWithZalo()">
      <img src="@/assets/images/icons/zalo-login.svg" alt="" />
      <p class="social-name pt-1 mb-0">Zalo</p>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "~sass-rem";
@import "@/assets/scss/social-login.scss";
</style>
<script>
import { mapActions } from "vuex";
import facebookLogin from "facebook-login-vuejs";
import jwt_decode from "jwt-decode";
import pkceChallenge from 'pkce-challenge'
import randomstring from 'randomstring'

export default {
  name: "social-login",
  components: { facebookLogin },
  created() { },
  mounted() {
    let routerBack = this.$router;
    let api = this.$store;
    async function handleCredentialResponse(response) {
      let responsePayload = jwt_decode(response.credential)
      if (responsePayload?.sub) {
        api.dispatch("auth/signInGG", { social_id: responsePayload.sub }).then((res) => {
          console.log(res)
          if (res)
            routerBack.back();
          else
            routerBack.push({
              name: "SignUpSocial",
              params: { method: "google", userid: responsePayload.sub, email: responsePayload.email },
            });
        }).catch((err) => {
          console.log(err);
          routerBack.push({
            name: "SignUpSocial",
            params: { method: "google", userid: responsePayload.sub, email: responsePayload.email },
          });
        })
      }
    }
    google.accounts.id.initialize({
      client_id: this.$store.state.MIX_GOOGLE_CLIENT_ID,
      callback: handleCredentialResponse
    });
    google.accounts.id.renderButton(
      document.getElementById("buttonDiv"),
      {
        type: "icon",
        shape: "circle",
        theme: "outline",
        text: "signin_with",
        size: "large",
        locale: "bs",
        logo_alignment: "center"
      }
      // customization attributes
    );
    // google.accounts.id.prompt(); // also display the One Tap dialog
  },
  methods: {
    ...mapActions({
      signinGG: "auth/signInGG",
      signInFaceBook: "auth/signInFaceBook",
      signInZalo: "auth/signInZalo",
      signInApple: "auth/signInApple",
    }),
    test: function () {
      console.log("test success!")
    },
    onLogout() {
      return true;
    },
    onSuccess(data) {
      let send_data = {
        userID: data.userData.sub,
      };
      this.signInApple({ send_data: send_data })
        .then((res) => {
          // this.$notify({ title: "Success", text: "Login success", type: "success" });
          if (res && res.token) {
            this.$router.push({
              name: "Main",
            });
          }
        })
        .catch((err) => {
          this.$router.push({
            name: "SignUpSocial",
            params: { method: "apple", userid: data.userData.sub },
          });
        });
    },
    onSuccessLogout() {
      this.signedIn = false;
    },
    onFailure(error) {
      console.log(error);
    },


    //login Google
    async loginWithGoogle() {
      // this.$refs.googleLogin.$el.click();
      console.log(this.$refs)
    },
    //login apple
    loginWithApple() {
      this.$refs.appleLogin.$el.click();

    },
    //login Facebook
    async loginWithFacebook() {
      await FB.getLoginStatus(async (response) => {
        if (response.status === "connected") {
          await FB.api("/me/permissions", "delete", null, () => FB.logout());
        }
      });
      var vm = this;
      await FB.login((response) => {
        if (response.authResponse) {
          vm.accessToken = response.authResponse.accessToken;
          vm.userID = response.authResponse.userID;
          let send_data = {
            userID: vm.userID,
            accessToken: vm.accessToken,
          };
          vm.signInFaceBook({ send_data: send_data })
            .then((res) => {
              // this.$notify({ title: "Success", text: "Login success", type: "success" });
              //FB.api('/me/permissions', 'delete', null, () => FB.logout());
              if (res && res.token) {
                // this.$router.push({
                //   name: "Main",
                // });
                this.$router.back();
              }
            })
            .catch((err) => {
              FB.api("/me/permissions", "delete", null, () => FB.logout());
              this.$router.push({
                name: "SignUpSocial",
                params: { method: "facebook", userid: response.authResponse.userID },
              });
            });
          return;
        }
        console.log("User login failed");
      });
    },
    //login zalo
    async loginWithZalo() {

      const pkceChallenge = require("pkce-challenge").default;
      let codeGenerator = pkceChallenge();
      let state = randomstring.generate(8);
      //zaloID
      const zsConfig = {
        appId: "2551556789795094841",
        redirectUri: `${this.$store.state.domain}`,
        //redirectUri: "https://life-on.me",
        //redirectUri: "https://dev2.lifeonkorea.com/",
        appSecret: "Kk43Fqd1awXHqIs5L108",
      };
      //const urlPermission = `https://oauth.zaloapp.com/v3/permission?app_id=${zsConfig.appId}&redirect_uri=${zsConfig.redirectUri}&state=active`;
      const urlPermission = `https://oauth.zaloapp.com/v4/permission?app_id=${zsConfig.appId}&redirect_uri=${zsConfig.redirectUri}&code_challenge=${codeGenerator.code_challenge}&state=${state}`;
      window.open(
        urlPermission,
        "zalo-login",
        "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
        350 +
        ", height=" +
        500 +
        ", top=" +
        Number(screen.height / 2 - 500 / 1) +
        ", left=" +
        Number(screen.width / 2 - 350 / 2)
      );
      this.helper.zaloToken = (res) => {
        let paramUrl = res.split("");
        const firstData = paramUrl.splice(6);
        firstData.splice(firstData.indexOf("&"));
        firstData.splice(0, firstData.indexOf("=") + 1);
        const codeResponse = firstData.join("");
        const params = {
          app_id: zsConfig.appId,
          //app_secret: zsConfig.appSecret,
          secret_key: zsConfig.appSecret,
          grant_type: "authorization_code",
          code: codeResponse,
          code_verifier: codeGenerator.code_verifier,
        };
        this.$http
          .post(`${this.$store.state.urlApi}/app/zalo/access_token`, params)
          .then((res) => {
            this.getInfoZalo(res.data.data.access_token);
          })
          .catch((error) => {
            console.log(error.message);
          });
      };


    },
    //login get info Zalo
    async getInfoZalo(data) {
      await this.$http
        .get(`${this.$store.state.urlApi}/app/zalo/me?access_token=${data}`)
        .then((res) => {
          let send_data = {
            userID: res.data.data.id,
          };
          this.signInZalo({ send_data: send_data })
            .then((res) => {
              // this.$notify({ title: "Success", text: "Login success", type: "success" });
              if (res && res.token) {
                // this.$router.push({
                //   name: "Main",
                // });
                this.$router.back();
              } else {
                this.$router.push({
                  name: "SignUpSocial",
                  params: { method: "zalo", userid: send_data.userID },
                });
              }
            })
            .catch((err) => {
              this.$router.push({
                name: "SignUpSocial",
                params: { method: "zalo", userid: send_data.userID },
              });
            });
        })
        .catch((error) => console.log(error.message));
    },
  },
};
</script>
