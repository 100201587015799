import axios from "axios";
import { create } from 'apisauce'
import store from "@/store";
import Vue from "vue";

const customAxiosInstance = axios.create({ baseURL: "" })
const apisauceInstance = create({ axiosInstance: customAxiosInstance })
export default {
  namespaced: true,
  state: {
    profileInfo: null,
    token: localStorage.getItem("token"),
    token_commerce: localStorage.getItem("token_commerce"),
    token_commerce_partner: localStorage.getItem("token_commerce_partner"),
  },
  getters: {
    getInfoToken: (state) => {
      return state.token;
    },
    getBaseURL: (state) => {
      return state.urlApi;
    }
  },
  mutations: {
    SET_TOKEN(state, payload) {
      state.token = payload ? payload.token : null;
      // state.token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2Njc0NTI5NTYsInVzZXJfaWQiOjIzNzYsInVzZXJfdHlwZSI6ImN1c3RvbWVyIn0.9rJkamLY0XZkRN1Cn3r5Se3O7OlpOgXPFAgvYvrY40c"
    },
    SET_TOKEN_COMMERCE(state, payload) {
      state.token_commerce = payload ? payload.commerce_token : null;
      state.token_commerce_partner = payload
        ? payload.commerce_partner_token
        : null;
    },

    SET_PROFILE_INFO(state, info) {
      if (info) {
        const fullName = `${info.first_name} ${info.last_name}`;
        state.profileInfo = { ...info, fullName };
      } else state.profileInfo = null;
    },
  },
  actions: {
    //log out
    signOut({ commit, state }) {
      commit("SET_TOKEN", null);
      commit("SET_TOKEN_COMMERCE", null);
      commit("SET_PROFILE_INFO", null);
    },
    //Login normal
    async signIn({ commit, state, dispatch }, credentials) {
      try {
        const data_req = {
          email: credentials.email,
          password: credentials.password,
          login_method: "system",
        };
        store.state.isLoading = true;
        const res = await apisauceInstance.post(
          `${store.state.urlApi}/app/auth/login-user`,
          data_req
        );
        if (res.data && res.data.data) {
          console.log("res", res);
          commit("SET_TOKEN", res.data.data);
          dispatch("getProfile");
          dispatch("getTokenCommerce");
          return res.data.data;
        } else {
        store.state.isLoading = false;
          Vue.notify({
            title: "Error",
            text: res.data.message,
            type: "error",
          });
        }
      } catch (error) {
        store.state.isLoading = false;
        throw error;
      }
    },
    // getTokenCommerce
    async getTokenCommerce({ commit, state, dispatch }) {
      try {
        const res = await axios.get(
          `${store.state.urlApi}/commerce-app/auth/users-commerce-token`,
          { headers: { Authorization: `Bearer ${state.token}` } }
        );
        if (res && res.data) {
          commit("SET_TOKEN_COMMERCE", res.data);
          // commit('SET_TOKEN_COMMERCE_PARTNER', res.data);
        }
      } catch (error) {
        throw error;
      }
    },
    //info user
    async getProfile({ commit, state, dispatch }) {
      if (!state.token) return;
      try {
        const res = await axios.get(`${store.state.urlApi}/app/user/profile`, {
          headers: { Authorization: `Bearer ${state.token}` },
        });
        if (res?.data && res?.data?.data) {
          commit("SET_PROFILE_INFO", res.data.data);
        } else {
          dispatch("signOut");
        }
      } catch (error) {
        throw error;
      }
    },
    // social login
    async signInSocial({ commit, state, dispatch }, credentials) {
      const sendData = {
        "login_method": credentials.social_type,
        "social_id":  credentials.social_id
      };
      store.state.isLoading = true;
      let res = await axios.post( `${store.state.urlApi}/app/auth/login-user`, sendData);
      if (res?.data && res?.data?.data) {
        commit('SET_TOKEN', res.data.data);
        dispatch('getProfile');
        dispatch('getTokenCommerce');
        return res.data.data;
      }  else 
      Vue.notify({
        title: "Warning",
        text: "Please register for an account!",
        type: "warn",
      });
    },
    // Google signin
    async signInGG({ commit, state, dispatch }, credentials) {
      const sendData = {
        login_method: "google",
        social_id: credentials.social_id,
      };
      store.state.isLoading = true;
      let res = await axios.post(
        `${store.state.urlApi}/app/auth/login-user`,
        sendData
      );
      if (res?.data && res?.data?.data) {
        commit("SET_TOKEN", res.data.data);
        dispatch("getProfile");
        dispatch("getTokenCommerce");
        return res.data.data;
      } else
        Vue.notify({
          title: "Warning",
          text: "Please register for an account!",
          type: "warn",
        });
    },
    // Facebook signin
    async signInFaceBook({ commit, state, dispatch }, credentials) {
      const sendData = {
        login_method: "facebook",
        social_id: credentials.send_data.userID,
      };
      store.state.isLoading = true;
      let res = await axios.post(
        `${store.state.urlApi}/app/auth/login-user`,
        sendData
      );
      if (res.data && res.data.data) {
        commit("SET_TOKEN", res.data.data);
        dispatch("getProfile");
        dispatch("getTokenCommerce");
        return res.data.data;
      } else
        Vue.notify({
          title: "Warning",
          text: "Please register for an account!",
          type: "warn",
        });
    },
    // Zalo signin
    async signInZalo({ commit, state, dispatch }, credentials) {
      const sendData = {
        login_method: "zalo",
        social_id: credentials.send_data.userID,
      };
      store.state.isLoading = true;
      let res = await axios.post(
        `${store.state.urlApi}/app/auth/login-user`,
        sendData
      );
      if (res.data && res.data.data) {
        commit("SET_TOKEN", res.data.data);
        dispatch("getProfile");
        dispatch("getTokenCommerce");
        return res.data.data;
      } else
        Vue.notify({
          title: "Warning",
          text: "Please register for an account!",
          type: "warn",
        });
    },

    // Zalo signin
    async signInApple({ commit, state, dispatch }, credentials) {
      const sendData = {
        login_method: "apple",
        social_id: credentials.send_data.userID,
      };
      store.state.isLoading = true;
      let res = await axios.post(
        `${store.state.urlApi}/app/auth/login-user`,
        sendData
      );
      if (res.data && res.data.data) {
        commit("SET_TOKEN", res.data.data);
        dispatch("getProfile");
        dispatch("getTokenCommerce");
        return res.data.data;
      } else
        Vue.notify({
          title: "Warning",
          text: "Please register for an account!",
          type: "warn",
        });
    },
  },
};
