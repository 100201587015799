/** @format */

import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login";
import Demo from "@/views/Home/Demo";
import Vr360Index  from "@/views/Vr360/Vr360Index"
import SendEmail from "../views/SendEmail";
import forgotRouter from "@/views/ForgotPassword/router/index";
import signupRouter from "@/views/SignUp/router/index";
import homeRouter from "@/views/Home/router/index";
import cartRouter from "@/views/Cart/router/index";
import contractorADRouter from "@/views/ContractorAD/router/index";
import contractorRouter from "@/views/Contractor/router/index";
import shopRouter from "@/views/Shop/router/index";
import interiorShareRouter from "@/views/InteriorShare/router/index";
import articleRouter from "@/views/Article/router/index";
import careerRouter from "@/views/Career/router/index";
import myPageRouter from "@/views/MyPage/router/index";
import paymentRouter from "@/views/Payment/router/index";
import paymentProductRouter from "@/views/PaymentProduct/router/index";
import $ from "jquery";
import store from "@/store";
import searchHomeRouter from "@/views/SearchHome/router";
import i18n from "../plugins/i18n";
import brandRouter from "@/views/Brand/router";
import packageRouter from "@/views/Package/router";

Vue.use(VueRouter);

const constants = {
  is_payment_product: false,
  payment_domain_test: "https://sandbox.megapay.vn",
  payment_domain_product: "https://pg.megapay.vn",
};

const paymentFinalRoute = !constants.is_payment_product
  ? [...paymentRouter]
  : [...paymentProductRouter];

const routes = [
  {
    path: "/",
    redirect: `${i18n.locale}`,
  },
  {
    path: "/:language",
    component: {
      render(h) {
        return h("router-view");
      },
    },
    children: [
      // { path: '*', redirect: '/' },
      {
        path: "login",
        name: "Login",
        component: Login,
      },
      {
        path: "send-email/:contractor_id",
        name: "SendEmail",
        component: SendEmail,
        meta: { requiresAuth: true },
      },
      {
        path: "demo/:vr_id",
        name: "Demo",
        component: Demo,
      },
      {
        path: "vr360/:slug",
        name: "Vr360Index",
        component: Vr360Index,
      },
      ...homeRouter,
      ...forgotRouter,
      ...signupRouter,
      ...cartRouter,
      ...contractorRouter,
      ...contractorADRouter,
      ...shopRouter,
      ...interiorShareRouter,
      ...articleRouter,
      ...careerRouter,
      ...myPageRouter,
      ...paymentFinalRoute,
      ...searchHomeRouter,
      ...brandRouter,
      ...packageRouter
    ],
  },
];

const getProfile = async () => {
  let abc = await this.$store.dispatch("auth/getProfile");
  if (abc) {
  }
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // if (to.hash === "#comments") {
    //   // setTimeout(() => {
    //   //   $("body,html").animate(
    //   //     { scrollTop: $("#comments").offset().top },
    //   //     2000
    //   //   );
    //   // }, 1000);
    // }
    // $("body,html").animate({ scrollTop: 0 }, "slow");
    console.log("savedPosition", savedPosition);
    return new Promise((resolve, reject) => {
      if (savedPosition) {
        setTimeout(() => {
          resolve({ left: 0, top: savedPosition });
        }, 2500);
      } else {
        $("body,html").animate({ scrollTop: 0 }, "slow");
        // resolve({ left: 0, top: 0 });
      }
    });
  },
});
router.beforeEach(async (to, from, next) => {
  await store.dispatch("auth/getProfile").then(() => {
    if (
      (to.meta.requiresAuth &&
        !store.state.auth.token &&
        to.name !== "Login") ||
      (to.meta.requiresCommerceParner &&
        !store.state.auth.token_commerce_partner)
    ) {
      next({ path: "/vi/login" });
    }
    next();
  });
});
export default router;
