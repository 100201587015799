import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth";
import furnitureDecor from "./furniture-decor";
import products from "./products";
import interiorShare from "./interior-share";
import interiorDesign from "./interior-design";
import banners from "./banners";
import productCategories from "./product-categories";

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    // urlApi: "https://prod.api.lifeonkorea.com",
    // url_mall: "https://prodmall.api.lifeonkorea.com",
    // domain: "https://life-on.me",
    urlApi: "https://dev.api.lifeonkorea.com",
    url_mall: "https://devmall.api.lifeonkorea.com",
    domain: "https://dev2.lifeonkorea.com",
    isLoading: false,
    checkTimeline: {},
    lang: "vi",
    mixpanel: "e30ea0df5950db10955b0f8fae789d9f",
    dynamicLink:
      "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyCOSc9OzI9H4ckZ1U9xVj471Ar7FgqFdP4",
    // domain: 'http://localhost:8080',
    MIX_GOOGLE_CLIENT_ID:
      "921522436125-31vep1t5e4frg78bf9n92kqp0ip8p2av.apps.googleusercontent.com",
  },
  mutations: {},
  actions: {},
  modules: {
    auth,
    furnitureDecor,
    products,
    interiorShare,
    interiorDesign,
    banners,
    productCategories,
  },
});
