import store from "@/store";
import axiosClient from "../plugins/axiosClient";

export default {
  namespaced: true,
  state: {
    interiorDesign: [],
    topDesignIncludeFurniture: null,
  },
//   getters: {
//     getInfoToken: (state) => {
//       return state.token;
//     },
//     getBaseURL: (state) => {
//       return state.urlApi;
//     }
//   },
  mutations: {
    GET_TOP_DESIGN_INCLUDE_FURNITURE(state, payload) {
      state.topDesignIncludeFurniture = payload ? payload : null;
    },
  },
  actions: {
    // getBestFurnitureDecorList
    async getTopDesignIncludeFurniture({ commit, state, dispatch }) {
      try {
        const res = await axiosClient.get(
          `${store.state.urlApi}/app/interior-design/design-have-product-random`,
          { headers: { "Accept-Language": store.state.lang } }
        );
        if (res && res.data) {
            commit("GET_TOP_DESIGN_INCLUDE_FURNITURE", res.data.data);
        }
      } catch (error) {
        throw error;
      }
    },
  },
};
